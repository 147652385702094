<template>
    <div class="user-container">
        <CRow>
            <CCol col="12">
                <CCard class="p-0">
                    <CCardHeader>
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h5>Monitoring Surat {{ typeProp }}</h5>
                            </div>
                        </div>
                           
                    </CCardHeader>
                    <CCardBody>
                        <div class="m-0 p-0">
                            <div class="d-flex" style="margin-bottom:10px!important">
                                <div class="flex-grow-1">
                 
                                </div>
                                <div class="flex-grow-0 ml-10">
                                    <div class="d-inline-flex justify-center">
                                        <div>
                                            <CButton v-if="!filter.showFilter" @click="filter.showFilter = !filter.showFilter" class="btn-outline-danger btn-sm">
                                                <font-awesome-icon icon="filter"/>
                                            </CButton>
                                            <CButton v-else @click="filter.showFilter = !filter.showFilter" class="btn-sm">
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CCollapse :show="filter.showFilter" :duration="400">
                                <CCard class="p-0">
                                    <CCardBody>
                                        <CRow class="filter-container">
                                            <CCol col="4">
                                                <CRow class="mb-1">
                                                    <CCol col="6">
                                                        <div class="form-input">
                                                            <label class="label">Tanggal Awal</label>
                                                            <date-picker 
                                                                v-model="filter.dateStart" 
                                                                :default-value="filter.dateStart"
                                                                placeholder="YYYY-MM-DD"
                                                                value-type="format"
                                                                type="date"
                                                                class="w-100">
                                                            </date-picker>
                                                        </div>

                                                    </CCol>
                                                    <CCol col="6">
                                                        <div class="form-input">
                                                            <label class="label">Tanggal Akhir</label>
                                                            <date-picker 
                                                                v-model="filter.dateFinish" 
                                                                :default-value="filter.dateFinish"
                                                                placeholder="YYYY-MM-DD"
                                                                value-type="format"
                                                                type="date"
                                                                class="w-100">
                                                            </date-picker>
                                                        </div>
                                                        
                                                    </CCol>
                                                </CRow>
                                                
                                                <CInput
                                                    v-model="filter.docNo"
                                                    label="Nomor Surat"
                                                    placeholder="Nomor Surat"/>
                                            </CCol>
                                            <CCol col="4">
                                                <div class='mb-1'>
                                                    <CInput
                                                        v-model="filter.title"
                                                        label="Judul Laporan"
                                                        placeholder="Judul Laporan"/>

                                                </div>
                                                <div class='mb-1'>
                                                        
                                                <label class="typo__label">Status Surat</label>
                                                    <multiselect
                                                        v-model="filter.selectedStatus"
                                                        :allow-empty="true"
                                                        :close-on-select="true"
                                                        :maxHeight="190"
                                                        :multiple="false"
                                                        :options="statusOptions"
                                                        deselectLabel=""
                                                        openDirection="bottom"
                                                        placeholder="Status Surat"
                                                        selectLabel=""
                                                        selectedLabel="Terpilih">
                                                        <template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
                                                    </multiselect>
                                                </div>
                                            </CCol>
                                            <CCol col="4">    
                                                <label class="typo__label">Urutkan</label>
                                                <multiselect
                                                    v-model="filter.selectedSortBy"
                                                    :allow-empty="true"
                                                    :close-on-select="true"
                                                    :maxHeight="190"
                                                    :multiple="false"
                                                    :options="sortByOptions"
                                                    deselectLabel=""
                                                    openDirection="bottom"
                                                    placeholder="Urutkan Berdasarkan"
                                                    selectLabel=""
                                                    selectedLabel="Terpilih"
                                                    label="label">
                                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
                                                </multiselect>
                                            </CCol>
                                        </CRow>
                                        <div class="d-flex justify-end float-right">
                                            <CButton @click="getMonitoringDocumentList()" color="success" class="btn-sm">
                                                Terapkan
                                            </CButton>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCollapse>
                        </div>
                        <v-data-table
                            :loading="loading"
                            loading-text="Memuat Data..."
                            :headers="conditionalHeaders()"
                            :multi-sort="false"
                            :items="monitoringDocumentList"
                            class="elevation-1">
                            
                                <template v-slot:item.receiver="{ item }">
                                    <div v-if="item.document_receiver.length == 1">
                                        {{ item.document_receiver[0].position }}
                                    </div>

                                    <CButton v-if="item.document_receiver.length > 1" color="info" @click="showMoreReceiver(item)" class=" btn-sm w-100">
                                        <font-awesome-icon icon="users" class="mr-10"/>
                                        Lihat lebih banyak
                                    </CButton>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <div v-if="item.document_position.length == 1">
                                        {{ item.document_position[0].position }}
                                    </div>

                                    <CButton v-if="item.document_position.length > 1" color="info" @click="showMorePosition(item)" class=" btn-sm w-100">
                                        <font-awesome-icon icon="users" class="mr-10"/>
                                        Lihat lebih banyak
                                    </CButton>
                                </template>
                                <template v-slot:item.detail="{ item }">
                                    <CButtonGroup>
                                        <CButton color="info" @click="showDetail(item)" class="btn-sm mr-2">
                                            Detil Surat
                                        </CButton>
                                        <CButton color="info" v-if="item.show_detail_doc" @click="showDocument(item)" class=" btn-sm">
                                            Buka Surat
                                        </CButton>
                                </CButtonGroup>
                                    
                                </template>
                                <template v-slot:no-data>
                                    <span>Data Tidak Ditemukan</span>
                                </template>
                        </v-data-table>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>


        <CModal :show.sync="showPopUpPosition" :closeOnBackdrop="false">
            <template #header>
                <h5 class="modal-title">Posisi Surat</h5>
            </template>
            <div class="p-1"> 
                <div class="row" v-for="(item, index) in documentPositionList" v-bind:key="item">
                    <div class="col-12">
                        {{ index+1 }} - {{ item.position }}
                    </div>
                </div>
            </div>
            
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Tutup</CButton>
            </template>
        </CModal>

        <CModal :show.sync="showPopupDetail" :closeOnBackdrop="false">
            <template #header>
                <h5 class="modal-title">Detil Surat</h5>
                <CButtonClose @click="closePopupModal()" class="text-gray"/>
            </template>
            <div class="p-0"> 
                <disposition-chart v-if="typeDetail == 'disposisi'"
                    :doc-id-prop="docId"
                    v-bind:is-refresh-prop="dispositionChartRefresh"
                />

                <approval-chart v-if="typeDetail == 'keluar dan nota dinas'" 
                    :doc-id-prop="docId"
                    v-bind:is-refresh-prop="approvalChartRefresh"/>

                <approval-chart v-if="typeDetail == 'verifikasi'" 
                    :doc-id-prop="docId"
                    v-bind:is-refresh-prop="verificationChartRefresh"/>
            </div>
            
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Tutup</CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import DispositionChart from '@/components/document/detail/DispositionChart.vue'
import ApprovalChart from '@/components/document/detail/ApprovalChart.vue'
//import VerificationChart from '@/components/document/detail/VerificationChart.vue'
export default {	
    
    props: {
		typeProp: {
			type: String,
			default: ''
		}
	},

    components: {
		'approval-chart': ApprovalChart,
		'disposition-chart': DispositionChart,
      //  'verification-chart': VerificationChart
    },

    data() {
        return {
            loading: false,
            filter: {
                showFilter: false,
                type: null,
                title: '',
                dateStart: null,
                dateFinish: null,
                selectedSortBy: null,
                selectedStatus: null,
                docNo: '',
            },

            statusOptions: ['Selesai', 'Sedang Proses'],

            sortByOptions: [
                    {
                        label: 'Tanggal Terbaru',
                        order_by: 'created_at',
                        sortBy: 'desc'
                    },   
                    {
                        label: 'Tanggal Terlama',
                        order_by: 'created_at',
                        sortBy: 'asc'
                    },
                    {
                        label: 'Urutkan Judul Berdasarkan Abjad',
                        order_by: 'title',
                        sortBy: 'asc'
                    },
                    {
                        label: 'Urutkan Judul dari Abjad Terakhir',
                        order_by: 'title',
                        sortBy: 'desc'
                    },
            ],
            headersOutNotaDinas: [
                    { text: 'Pembuat Surat', value: 'reporter_position.position', align: 'left', sortable: false },
                    { text: 'Perihal Surat', value: 'title', align: 'left', sortable: false },
                    { text: 'Tgl Surat', value: 'created_at', align: 'left', sortable: false },
                    { text: 'Posisi', value: 'actions', align: 'left', sortable: false  },
                    { text: 'Status', value: 'status', align: 'left', sortable: false, width: 120 },
                    { text: 'No Surat', value: 'doc_no', align: 'left', sortable: false},
                    { text: 'Penerima Surat', value: 'receiver', align: 'left', sortable: false, width: 120},
                    { text: 'Aksi', value: 'detail', sortable: false, align: 'center', width: 200 },
            ],
            headersIn: [
                    { text: 'No. Agenda Surat', value: 'no_agenda', align: 'left', sortable: false },
                    { text: 'Tgl Surat', value: 'created_at', align: 'left', sortable: false },
                    { text: 'No Surat', value: 'doc_no', align: 'left', sortable: false},
                    { text: 'Dari Siapa', value: 'reporter_position.position', align: 'left', sortable: false },
                    { text: 'Perihal Surat', value: 'title', align: 'left', sortable: false },
                    { text: 'Posisi', value: 'actions', align: 'left', sortable: false  },
                    { text: 'Status', value: 'status', align: 'left', sortable: false, width: 120 },
                    { text: 'Aksi', value: 'detail', sortable: false, align: 'center', width: 200 },
            ],

            monitoringDocumentList: [],

            showPopUpPosition: false,

            documentPositionList: [],
            docId: null,

            showPopupDetail: false,
            detailData: null,


            dispositionChartRefresh: false,
            approvalChartRefresh: false,
            verificationChartRefresh: false,
            typeDetail: null
        }
    },
    mounted() {
        this.getMonitoringDocumentList()
    },

    methods: {
        getMonitoringDocumentList(){
            this.loading = true

            this.filter.type = this.typeProp
            this.$store.dispatch(
                {
                    type: 'document/getMonitoringDocument',
                    filter: this.filter
                }
            ).then(res=> {
                this.monitoringDocumentList = res.result
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal mengambil data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.loading = false
            })
        },

        showMorePosition(item){
            this.showPopUpPosition = true
            this.documentPositionList = item.document_position
        },

        showMoreReceiver(item) {
            this.showPopUpPosition = true
            this.documentPositionList = item.document_receiver
        },

        closePopupModal() {
            this.showPopUpPosition = false
            this.showPopupDetail = false

            this.dispositionChartRefresh = false
            this.approvalChartRefresh = false
            this.verificationChartRefresh = false
        },

        showDetail(item) {
            let typePath = ''

            this.docId = item.doc_id
            this.typeDetail = item.type_monitoring
            if(this.typeDetail == 'disposisi') {
                this.dispositionChartRefresh = true
            } else if (this.typeDetail == 'keluar dan nota dinas') {
                this.approvalChartRefresh = true
            } else if (this.typeDetail == 'verifikasi') {
                this.verificationChartRefresh = true
            }
            this.showPopupDetail = true
        },

        showDocument(item) {
            let docId = item.doc_id

            let isForceAccess = localStorage.getItem('isForceAccess')
            if(isForceAccess != null) localStorage.removeItem('isForceAccess')
            localStorage.setItem('isForceAccess', true)

            let reminderReceiver = localStorage.getItem('reminderReceiver')
            if(reminderReceiver != null) localStorage.removeItem('reminderReceiver')
            localStorage.setItem('reminderReceiver', item.document_position[0].user_id)

            if(this.typeProp == 'Masuk') {
                this.$router.push('/document_in/detail/' + docId)
            } else if(this.typeProp == 'Keluar') {
                this.$router.push('/document_out/detail/' + docId)
            } else if (this.typeProp == 'Nota Dinas') {
                this.$router.push('/nota_dinas/detail/' + docId)
            }
        },

        conditionalHeaders() {
            if(this.typeProp == 'Masuk') {
                return this.headersIn
            } else {
                return this.headersOutNotaDinas
            }
        }
    },
}

</script>