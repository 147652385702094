<template>
    <CCard class="p-3 mb-5">
        <CCardBody class="d-flex justify-center" style="overflow-x:auto">
            <div v-if="loading" class="loader content-center p-2">
                <v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
            </div>
            <vue-org-chart v-if="dataList.length > 0" class="w-100 min-vh-100" :datasource="treeData">
                <template slot-scope="{nodeData}">
                    <div class="card-org">
                        <div class="fw-600 pb-1 bd-1">{{ nodeData.position }}</div>
                        <small class="help-block mb-5">{{ nodeData.fullname }}</small>
                        <div class="fw-600 small mt-1">Status:</div>
                        <p class="help-block small mb-0">{{ nodeData.status }}</p>
                        <p class="help-block small">{{ nodeData.created_at }}</p>
                    </div>
                </template>
            </vue-org-chart>
            <div v-else class="text-center p-3">
                <font-awesome-icon icon="info-circle" size="2x" class="text-info mb-1"/><br/>
                Monitoring Approval Tidak Tersedia
            </div>
        </CCardBody>
    </CCard>
</template>

<script>
import { mapState } from 'vuex'
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'

export default {
    props: {
        docIdProp: {
            type: Number,
            required: true
        },
        isRefreshProp: {
            type: Boolean
        }
    },
    components: {
        "vue-org-chart": OrganizationChart
    },
    data () {
        return {
            loading: false,
            dispositionFinishStatus: false,
            dataList: [],
            dispositionFeedback: {
                isDone: false,
                isReply: false,
                isRead: false
            },
            treeData: {}
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserId: 'user_id',
            loggedUserPosition: 'position',
            username: 'username',
            fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64',
            email: 'email'
        })
    },
    mounted () {
        this.getDataList()
    },
    watch: {
        isRefreshProp: function (val) {
            if(this.isRefreshProp) {
                this.getDataList()
                this.isRefreshProp = false
            }
        }
    },
    methods: {
        getDataList () {
            this.loading = true
            this.$store.dispatch({
                type: 'document/getApprovalMonitoring',
                filter: {
                    docId: this.docIdProp
                }
            }).then( res => {
                if (res) {
                    let data = res.receiver_list
                    this.dataList = data
                    this.dispositionFinishStatus = res.finish_status
                }
            }).then( d => {
                let newTreeData = this.dataFormating(this.dataList[0])
                this.treeData = newTreeData
            }).catch ( err => {
                console.log('Error fetch Disposition Monitoring data')
                console.log(err.message)
                this.dataList = []
            }).finally ( () => {
                this.loading = false
            })
        },
        dataFormating (item) {
            let rowData = {
                tag_id: item.tag_id,
                fullname: item.fullname,
                position: item.position,
                status: item.status,
                created_at: item.created_at,
                children: [],
            }
            
            if (item.child.length > 0) {
                item.child.forEach( itemChild => {
                    let newNode = this.dataFormating(itemChild)
                    rowData.children.push(newNode)
                })
            }
            
            return rowData
        }
    }
}
</script>

<style scoped lang="scss">
.card-org {
    padding: 10px;
    border: solid 1px #EEEEEE;
    border-radius: 5px;
    font-size: 12px;
}
.fw-600 {
    font-weight: 600;
}
.bd-1 {
    border-bottom: solid 1px #EEEEEE;
    margin-bottom: 8px;
}
.w-100 {
    width: 100%;
}
.mt-10 {
    margin-top: 10px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-50 {
    margin-left: 50px !important;
}
.signaturePadContainer {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.customCursor {
    cursor: url('~@/assets/img/ink_pen_cursor.png'), auto;
}
.page-control {
    position: absolute;
    top:15px;
    right:15px;
    z-index: 1;
}
#app {
    background-color: #f5f5f5;
}
#detail-info-table td {
    font-size: 13px;
    padding: 0.25rem;
    vertical-align: top;
}
.attachment-clip {
    width: 160px;
    margin-right: 15px;
}
.comments-list-action {
    margin-bottom: 65px;
    padding: 0;
}
.clickable {
    cursor: pointer !important;
}
</style>